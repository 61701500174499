const SESSION_TIMEOUT_SECONDS = 43200; // 60 sec * 60 minutes * 12 hours
const SESSION_TIMEOUT = SESSION_TIMEOUT_SECONDS * 1000 - 10000;
let token = "";
const httpUser = {
  isSuperUser: false,
  canReadReport: false,
  approvedNeoNatalie: false,
  approvedAdmin: false,
  uploadSiteId: null,
  uploadSiteName: null,
  userId: null,
  subscribesToLiveborn: null,
  subscribesToMoyo: null,
  subscribesToNeoBeat: null,
  subscribesToNeoNatalie: null,
  authenticatedWithTwoFactor: false,
  subscribesToMamaNatalie: null,
  subscribesToScenarioTraining: null,
};

let sapUser = {
  email: "",
};

const getNewJwt = async () => {
  await ensureGigyaLoaded();

  return new Promise((resolve, reject) => {
    window.gigya.accounts.getJWT({
      expiration: SESSION_TIMEOUT_SECONDS,
      fields: "firstName, lastName, email",
      callback: (arg) => {
        if (arg.errorCode === 0) {
          console.log("SESSION_TIMEOUT: ", SESSION_TIMEOUT);
          setTimeout(() => logout(false), SESSION_TIMEOUT);
          resolve(arg.id_token);
        }
        reject();
      },
    });
  });
};

const checkUser = async () => {
  await ensureGigyaLoaded();

  // Make gigya method awaitable:
  return new Promise((resolve, reject) => {
    window.gigya.accounts.getAccountInfo({
      callback: (e) => {
        if (e.errorCode === 0) {
          setFavoriteColors(e.profile.email);
          resolve(e.profile);
        } else {
          reject();
        }
      },
    });
  });
};

const setFavoriteColors = (username) => {
  const theme = document.querySelector(".theme");

  switch (username) {
    case "marzie.sorati@laerdal.com":
      theme.style.setProperty("--primary-color", "#ff9eb5");
      theme.style.setProperty("--primary-color-opacity", "#ff9eb514");
      theme.style.setProperty("--primary-action-color", "#fd8e8f");
      theme.style.setProperty("--primary-focus-border-color", "#fc8882");
      break;
  }
};

const gigyaLogout = async () => {
  await ensureGigyaLoaded();

  // Make the Gigya logout method awaitable:
  return new Promise((resolve, reject) => {
    window.gigya.accounts.logout({
      callback: (response) => {
        if (!!response && response.errorCode === 0) {
          resolve();
        } else {
          console.log(`Logout failed: ${response.errorMessage}`);
          reject(response);
        }
      },
    });
  });
};

const ensureGigyaLoaded = () => {
  return new Promise((resolve) => {
    if (!window.gigya) {
      window.onGigyaServiceReady = function () {
        resolve();
      };
    } else {
      resolve();
    }
  });
};

const logout = async (use2faUponNextLogin) => {
  const use2FactorAuth = use2faUponNextLogin ? "true" : "";
  sessionStorage.setItem("use2FactorAuth", use2FactorAuth);
  await gigyaLogout();
  // Need hard refresh since we have to include different gigya js.
  // (Api key is embedded in src ref)
  window.location.reload();
};

export const setUploadSiteOnHttpUser = (id, name) => {
  httpUser.uploadSiteId = id;
  httpUser.uploadSiteName = name;
};

export default {
  getHttpUser() {
    return httpUser;
  },
  getUser() {
    return sapUser;
  },
  changePassword() {},
  isAuthenticated() {
    // If there is token, we assume we are authenticated
    const authenticated = !!token;
    return authenticated;
  },
  sethttpUser(user) {
    httpUser.isSuperUser = user.isSuperUser;
    httpUser.canReadReport = user.canReadReport;
    httpUser.approvedNeoNatalie = user.approvedNeoNatalie;
    httpUser.approvedAdmin = user.approvedAdmin;
    httpUser.uploadSiteId = user.uploadSiteId;
    httpUser.userId = user.userId;
    httpUser.subscribesToLiveborn = user.subscribesToLiveborn;
    httpUser.subscribesToMoyo = user.subscribesToMoyo;
    httpUser.subscribesToNeoBeat = user.subscribesToNeoBeat;
    httpUser.subscribesToNeoNatalie = user.subscribesToNeoNatalie;
    httpUser.subscribesToScenarioTraining = user.subscribesToScenarioTraining;
    httpUser.subscribesToMamaNatalie = user.subscribesToMamaNatalie;
    httpUser.uploadSiteName = user.uploadSiteName;
    httpUser.authenticatedWithTwoFactor = user.authenticatedWithTwoFactor;
  },
  async getUserAndToken() {
    console.log("getUserAndToken()");
    try {
      const user = await checkUser();
      const jwt = await getNewJwt();
      token = jwt;
      sapUser = user;
      return { user, jwt };
    } catch (error) {
      console.log(error);
      return { error, jwt: null };
    }
  },
  async navigateToLogin(redirectPath, redirectQuery) {
    await ensureGigyaLoaded();

    const queryString = new URLSearchParams(redirectQuery).toString();
    const redirectURL =
      window.location.origin +
      (queryString ? `${redirectPath}?${queryString}` : redirectPath);
    console.log("RedirectURL:", redirectURL);

    // Make gigya method awaitable:
    return new Promise((resolve, reject) => {
      window.gigya.sso.login({
        authFlow: "redirect",
        redirectURL,
        useChildContext: true,
        context: {
          application: "Liveborn",
          lang: "en-US",
          allowRegistration: true,
        },
        callback: (e) => {
          if (e.errorCode === 0) {
            resolve(e.profile);
          } else {
            reject();
          }
        },
      });
    });
  },
  logout,
  async handle2faLogin() {
    var goto2FA = await window.Swal.fire({
      title: "Requires 2-Factor-Authentication",
      showCancelButton: true,
      confirmButtonText: "Login with authenticator app",
    });
    if (goto2FA.isConfirmed) {
      await this.logout(true);
    }
  },
  isNeoNatalieOnlyUser() {
    return (
      httpUser.subscribesToNeoNatalie &&
      !httpUser.subscribesToLiveborn &&
      !httpUser.subscribesToMoyo &&
      !httpUser.subscribesToNeoBeat
    );
  },
};
