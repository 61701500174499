<template>
  <nav
    id="livebornNavbar"
    class="navbar navbar-style navbar-light bg-white navbar-expand-lg align-items-stretch"
  >
    <div class="container-fluid align-items-stretch">
      <button
        class="btn navbar-toggler"
        style="border: none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#nav-collapse"
        aria-controls="nav-collapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <a class="navbar-brand" href="/" style="height: 70px">
        <img
          style="height: 100%"
          alt="Laerdal icon"
          src="@/assets/images/laerdal_icon.svg"
        />
      </a>

      <div
        id="nav-collapse"
        class="collapse navbar-collapse align-items-stretch"
      >
        <ul class="navbar-nav ml-auto">
          <li v-for="(route, index) in routes" :key="index" class="nav-item">
            <navbar-router-link
              style="height: 100%"
              :link-path="route.path"
              :link-title="route.display"
            />
          </li>

          <li class="nav-item dropdown nav-item-style dropdown-menu-container">
            <a
              id="navbarDropdown"
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >Dashboards</a
            >
            <ul
              v-if="approvedReport"
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              <li v-if="subscribesToLiveborn" role="presentation">
                <router-link
                  class="dropdown-item"
                  to="/reportDetails/liveborn"
                  style="color: black"
                  role="menuitem"
                  >Liveborn report</router-link
                >
              </li>

              <li v-if="subscribesToNeoNatalie">
                <router-link
                  class="dropdown-item"
                  to="/reportDetails/neonatalie"
                  style="color: black"
                  role="menuitem"
                  >NeoNatalie Live report</router-link
                >
              </li>
            </ul>
          </li>
        </ul>

        <div
          v-if="isTestEnv"
          class="navbar-brand ms-auto d-flex align-items-center"
        >
          <span>TEST ENVIRONMENT</span>
        </div>

        <div
          v-if="isLghEnv"
          class="navbar-brand ms-auto d-flex align-items-center"
        >
          <span>LGH ENVIRONMENT</span>
        </div>

        <div
          v-if="isLghDevEnv"
          class="navbar-brand ms-auto d-flex align-items-center"
        >
          <span>LGH DEV ENVIRONMENT</span>
        </div>

        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown dropdown-menu-container">
            <small>{{ sapUser.email }}</small>
            <button
              id="userDropdown"
              class="btn btn-primary btn-square rounded-circle"
              style="margin-left: 10px"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <laerdal-icon icon="UserPerson" fill="white"></laerdal-icon>
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="userDropdown"
            >
              <li role="presentation" style="color: black">
                <router-link
                  to="/my-profile"
                  class="dropdown-item d-flex"
                  role="menuitem"
                >
                  <laerdal-icon
                    class="list-icon"
                    height="18"
                    width="18"
                    icon="UserPerson"
                  ></laerdal-icon>
                  My permissions
                </router-link>
              </li>
              <li v-show="isSuperUser" role="presentation" style="color: black">
                <router-link
                  to="/siteadmin-users"
                  class="dropdown-item d-flex"
                  role="menuitem"
                >
                  <laerdal-icon
                    class="list-icon"
                    height="18"
                    width="18"
                    :icon="
                      authenticatedWithTwoFactor ? 'Institute' : 'LockedOn'
                    "
                  ></laerdal-icon>
                  Manage upload sites
                </router-link>
              </li>
              <li v-show="isSuperUser" role="presentation">
                <router-link
                  to="/siteadmin-overview"
                  class="dropdown-item d-flex"
                  role="menuitem"
                >
                  <laerdal-icon
                    class="list-icon"
                    height="18"
                    width="18"
                    :icon="
                      authenticatedWithTwoFactor ? 'Hierarchy' : 'LockedOn'
                    "
                  ></laerdal-icon>
                  Manage user-permissions
                </router-link>
              </li>
              <li
                v-show="isSuperUser && !isNeoNatalieOnlyUser"
                role="presentation"
              >
                <router-link
                  to="/site-settings"
                  class="dropdown-item d-flex"
                  role="menuitem"
                >
                  <laerdal-icon
                    class="list-icon"
                    height="18"
                    width="18"
                    :icon="
                      authenticatedWithTwoFactor ? 'GearSettings' : 'LockedOn'
                    "
                  ></laerdal-icon>
                  Manage Liveborn App settings
                </router-link>
              </li>
              <li
                v-show="isSuperUser"
                role="presentation"
              >
                <router-link
                  to="/scenario-training-site-settings"
                  class="dropdown-item d-flex"
                  role="menuitem"
                >
                  <laerdal-icon
                    class="list-icon"
                    height="18"
                    width="18"
                    :icon="
                      authenticatedWithTwoFactor ? 'GearSettings' : 'LockedOn'
                    "
                  ></laerdal-icon>
                  Manage LIFT Scenarios
                </router-link>
              </li>
              <li v-show="approvedNeoNatalie" role="presentation">
                <router-link
                  to="/lift-learners"
                  class="dropdown-item d-flex"
                  role="menuitem"
                >
                  <laerdal-icon
                    class="list-icon"
                    height="18"
                    width="18"
                    icon="TeamWork"
                  ></laerdal-icon>
                  Manage Learners
                </router-link>
              </li>

              <li v-show="approvedAdmin" role="presentation">
                <router-link
                  to="/admin"
                  class="dropdown-item d-flex"
                  role="menuitem"
                >
                  <laerdal-icon
                    class="list-icon"
                    height="18"
                    width="18"
                    :icon="
                      authenticatedWithTwoFactor
                        ? 'AwardWinningPerson'
                        : 'LockedOn'
                    "
                  ></laerdal-icon>
                  Admin
                </router-link>
              </li>

              <li role="presentation" style="padding: 0.25rem 0.5rem">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  style="width: 100%"
                  @click="logout()"
                >
                  Logout
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <hr style="margin: unset" />
</template>

<script>
import LaerdalIcon from "./laerdal-icon.vue";
import { visibleRoutes } from "../router/routes";
import authService from "../services/auth.service";
import NavbarRouterLink from "./navbar-router-link";
import { getEnvironment, ENV_TEST, ENV_LGH, ENV_LGH_DEV } from "../services/env";

export default {
  components: {
    "laerdal-icon": LaerdalIcon,
    "navbar-router-link": NavbarRouterLink,
  },
  data() {
    return {
      sapUser: {},
      visibleRoutes,
      open: false,
      isTestEnv: getEnvironment() === ENV_TEST,
      isLghEnv: getEnvironment() === ENV_LGH,
      isLghDevEnv: getEnvironment() === ENV_LGH_DEV,
      isSuperUser: false,
      approvedReport: false,
      approvedNeoNatalie: false,
      approvedAdmin: false,
      authenticatedWithTwoFactor: false,
      subscribesToLiveborn: false,
      subscribesToMoyo: false,
      subscribesToNeoBeat: false,
      subscribesToNeoNatalie: false,
      subscribesToMamaNatalie: false,
      isNeoNatalieOnlyUser: false,
      showScenarioTrainingDev: false,
    };
  },
  computed: {
    routes() {
      return this.visibleRoutes.filter((route) => {
        if (route.path === "/") {
          return this.subscribesToLiveborn;
        }

        if (route.path === "/neoBeatEpisodes") {
          return this.subscribesToNeoBeat;
        }

        if (route.path === "/moyoEpisodes") {
          return this.subscribesToMoyo;
        }

        if (route.path === "/neoNatalieSessions") {
          return this.subscribesToNeoNatalie;
        }

        if (route.path === "/mamaNatalieScenarioTraining") {
          return this.subscribesToMamaNatalie;
        }

        if (route.display === "Dashboards") {
          return this.approvedReport;
        }
        return true;
      });
    },
  },
  created() {
    this.sapUser = authService.getUser();
    const userPermissions = authService.getHttpUser();
    this.isSuperUser = userPermissions.isSuperUser;
    this.approvedReport = userPermissions.canReadReport;
    this.approvedNeoNatalie = userPermissions.approvedNeoNatalie;
    this.approvedScenarioTraining = userPermissions.approvedScenarioTraining;
    this.approvedAdmin = userPermissions.approvedAdmin;
    this.authenticatedWithTwoFactor =
      userPermissions.authenticatedWithTwoFactor;
    this.subscribesToLiveborn = userPermissions.subscribesToLiveborn;
    this.subscribesToMoyo = userPermissions.subscribesToMoyo;
    this.subscribesToNeoBeat = userPermissions.subscribesToNeoBeat;
    this.subscribesToNeoNatalie = userPermissions.subscribesToNeoNatalie;
    this.isNeoNatalieOnlyUser = authService.isNeoNatalieOnlyUser();
  },
  mounted() {},
  methods: {
    async logout() {
      await authService.logout(false);
    },
  },
};
</script>

<style scoped>
.navbar-style {
  padding: 0px;
}

.dropdown-menu-container {
  min-height: 100%;
  border-bottom-style: solid;
  border-bottom-color: var(--bs-white);
  display: flex;
  align-items: center;
}

.list-icon {
  width: 1.5rem;
}

.dropdown-button {
  padding: 0.25rem 1rem;
}

button:focus {
  outline: none;
  box-shadow: none;
}
</style>
